/** @jsx jsx */
import { useState, useEffect } from 'react';
import { Box, Flex, Heading, Text, jsx } from 'theme-ui';
import { utils } from '@arfabrands/gatsby-theme-assembly';

import ProductAddToCartButton from '~/components/Product/AddToCartButton';
import ProductOutOfStock from '~/components/Product/OutOfStock';
import ProductQuantitySelector from '~/components/Product/QuantitySelector';
import ProductSizeSelector from '~/components/Product/SizeSelector';
import ProductColorSelector from '~/components/Product/ColorSelector';

const ProductDetails = ({ product, ...props }) => {
  const {
    comingSoon,
    soldOut,
    longDescription,
    name,
    price,
    regularPrice,
    sku,
    variants,
  } = product;

  const { toUsdCurrency } = utils;
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [selectedSize, setSelectedSize] = useState('S');

  const [selectedColor, setSelectedColor] = useState('Black');

  const [selectedSku, setSelectedSku] = useState();

  const colors = [];
  const sizes = [];

  // TODO: improve readability of what is going on here

  variants.forEach((variant) => {
    const size = variant.optionValues.filter(
      (v) => v.option_type.filter((o) => o.slug === 'size').length > 0
    )[0];
    const color = variant.optionValues.filter(
      (v) => v.option_type.filter((o) => o.slug === 'color').length > 0
    )[0];
    if (!sizes.find((s) => s.name === size.slug)) {
      sizes.push({ name: size.slug, presentation: size.presentation });
    }
    if (!colors.find((c) => c.name === color.name)) {
      colors.push({ name: color.name, presentation: color.presentation });
    }
  });

  useEffect(() => {
    variants.forEach((variant) => {
      const size = variant.optionValues.filter(
        (v) => v.option_type.filter((o) => o.slug === 'size').length > 0
      )[0];
      const color = variant.optionValues.filter(
        (v) => v.option_type.filter((o) => o.slug === 'color').length > 0
      )[0];
      if (color.name === selectedColor && size.presentation === selectedSize) {
        setSelectedSku(variant.sku);
      }
    });
  }, [selectedColor, selectedSize]);

  return (
    <Flex
      {...props}
      px={3}
      sx={{
        flexDirection: 'column',
        textAlign: 'left',
        width: '100%',
      }}
    >
      <Heading
        as="h1"
        sx={{
          fontSize: '34px',
          lineHeight: '31px',
          fontWeight: 700,
          color: '#9f1c28',
          textTransform: 'uppercase',
        }}
      >
        {name}
      </Heading>
      {price !== regularPrice ? (
        <Flex sx={{ marginTop: '15px' }}>
          <Text
            sx={{
              fontWeight: 500,
              fontSize: '19px',
              lineHeight: '31px',
              fontStyle: 'italic',
              color: '#9f1c28',
              textDecoration: 'line-through',
              marginRight: '5px',
            }}
          >
            {toUsdCurrency(regularPrice)}
          </Text>
          <Text
            sx={{
              fontWeight: 500,
              fontSize: '19px',
              lineHeight: '31px',
              fontStyle: 'italic',
              color: '#9f1c28',
            }}
          >
            {toUsdCurrency(price)}
          </Text>
        </Flex>
      ) : (
        <Text
          sx={{
            fontWeight: 500,
            fontSize: '19px',
            lineHeight: '31px',
            fontStyle: 'italic',
            color: '#9f1c28',
            marginTop: '15px',
          }}
        >
          {toUsdCurrency(price)}
        </Text>
      )}

      {longDescription && (
        <div
          sx={{
            '& p': {
              marginTop: '15px',
              marginBottom: '35.5px',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '16.8px',
            },
          }}
          dangerouslySetInnerHTML={{
            __html: longDescription.childMarkdownRemark.html,
          }}
        />
      )}

      <ProductSizeSelector
        options={sizes}
        setSelectedSize={setSelectedSize}
        selectedSize={selectedSize}
      />

      <Flex
        sx={{
          alignItems: 'center',
          marginTop: '30px',
        }}
      >
        <Text sx={{ marginRight: '15px' }}>QTY</Text>
        <ProductQuantitySelector
          sx={{ maxWidth: '82px' }}
          handleChange={(value) => setSelectedQuantity(parseInt(value))}
        />
      </Flex>

      <Flex
        sx={{
          flexDirection: 'column',
          marginLeft: ['-10px', null, 0],
          marginTop: '30px',
        }}
      >
        <Text
          sx={{
            textTransform: 'uppercase',
            fontSize: '15px',
            lineHeight: '31px',
            fontWeight: 500,
            fontStyle: 'italic',
            '& > span:first-of-type': {
              fontWeight: 700,
            },
          }}
        >
          <span>Color: </span>
          <span>{selectedColor}</span>
        </Text>
        <ProductColorSelector
          sx={{ maxWidth: '82px' }}
          options={colors}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
        />
      </Flex>

      {selectedSku && (
        <Box
          sx={{
            alignSelf: 'center',
            justifySelf: 'center',
            width: '100%',
          }}
        >
          <ProductAddToCartButton
            sx={{
              marginTop: '30px',
              height: '42px',
              backgroundColor: 'alizarinCrimson',
              '& > div': {
                fontSize: ['18px', '18px', '21px'],
                lineHeight: ['21.6px', '21.6px', '58px'],
                letterSpacing: '0.5px',
                fontWeight: 500,
              },
            }}
            soldOut={soldOut}
            comingSoon={comingSoon}
            price={price}
            quantity={selectedQuantity}
            size={selectedSize}
            color={selectedColor}
            regularPrice={regularPrice}
            sku={selectedSku}
          />
        </Box>
      )}

      {(soldOut || comingSoon) && <ProductOutOfStock sku={sku} />}
    </Flex>
  );
};

export default ProductDetails;
