/** @jsx jsx */
import PropTypes from 'prop-types';
import { Flex, jsx, Box, Spinner } from 'theme-ui';

const ColorSelector = ({
  handleChange,
  options = [],
  setSelectedColor,
  selectedColor,
  ...props
}) => {
  const { isLoading } = props;

  options.sort((a, b) => a.presentation - b.presentation);

  return (
    <Flex
      sx={{
        minWidth: '80px',
      }}
    >
      {isLoading && <Spinner color="inherit" />}
      {!isLoading &&
        options.map(({ name, presentation }) => (
          <Box
            sx={{
              height: '29px',
              width: '29px',
              borderRadius: '50%',
              backgroundColor: presentation,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '2px',
              cursor: 'pointer',
              '&.color-selected': {
                border: '1px solid white',
              },
            }}
            key={name}
            onClick={() => {
              setSelectedColor(name);
            }}
            className={`${selectedColor === name ? 'color-selected' : ''}`}
          />
        ))}
    </Flex>
  );
};

ColorSelector.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      presentation: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ),
};

export default ColorSelector;
