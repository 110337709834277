import React from 'react';
import { graphql } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import ProductPage from '~/components/Product/Page';

const ProductTemplate = ({ data: { product, bg, bgMobile } }) => (
  <Layout>
    <Metadata title={product.name} description={product.shortDescription} />
    <ProductPage product={product} bg={bg} bgMobile={bgMobile} />
  </Layout>
);

export const query = graphql`
  query ProductQuery($slug: String, $locale: String) {
    product: contentfulProduct(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...ProductPageFragment
    }
    bg: contentfulAsset(title: { eq: "Merch-Background" }) {
      title
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    bgMobile: contentfulAsset(title: { eq: "merchbg-mobile" }) {
      title
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`;

export default ProductTemplate;
