/** @jsx jsx */
import PropTypes from 'prop-types';
import { Flex, jsx, Box, Spinner, Text } from 'theme-ui';

const SizeSelector = ({
  handleChange,
  options = [],
  setSelectedSize,
  selectedSize,
  ...props
}) => {
  const { isLoading } = props;
  const sizeOrder = { S: 0, M: 1, L: 2, XL: 3 };

  options.sort((a, b) => sizeOrder[a.presentation] - sizeOrder[b.presentation]);

  return (
    <Flex
      sx={{
        minWidth: '80px',
      }}
    >
      {isLoading && <Spinner color="inherit" />}
      {!isLoading &&
        options.map(({ presentation }) => (
          <Box
            sx={{
              height: '38px',
              width: '41px',
              borderRadius: '5px',
              border: '1px solid black',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 2px',
              cursor: 'pointer',
            }}
            key={presentation}
            onClick={() => {
              setSelectedSize(presentation);
            }}
            className={`${
              selectedSize === presentation ? 'size-selected' : ''
            }`}
          >
            <Text
              sx={{
                fontWeight: 700,
                fontStyle: 'italic',
                fontSize: '15px',
              }}
            >
              {presentation}
            </Text>
          </Box>
        ))}
    </Flex>
  );
};

SizeSelector.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      presentation: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ),
};

export default SizeSelector;
