/** @jsx jsx */
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, Flex, jsx } from 'theme-ui';
import Thumbnail from '~/components/Generic/Thumbnail';

const ProductImages = ({ product }) => {
  const { images, mainImage } = product;
  const productImages = images && images.length > 0 ? images : [mainImage];
  const [selectedImage, setSelectedImage] = useState(productImages[0]);

  return (
    <Flex
      sx={{
        position: 'relative',
        flexDirection: 'row-reverse',
        height: [null, null, null, '415px', '550px'],
        width: [null, null, null, '100%', '600px'],
      }}
    >
      <Box sx={{ height: '100%', width: [null, null, null, '450px', '600px'] }}>
        <Img
          fluid={selectedImage.fluid}
          imgStyle={{
            height: '550px',
            width: '100%',
          }}
        />
      </Box>

      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          width: '30%',
          marginRight: '15px',
        }}
      >
        {productImages
          .filter((img) => img.id !== selectedImage.id)
          .map((productImage, index) => (
            <Thumbnail
              key={index}
              image={productImage}
              handleClick={setSelectedImage}
              selected={selectedImage.id === productImage.id}
            />
          ))}
      </Flex>
    </Flex>
  );
};

ProductImages.propTypes = {
  product: PropTypes.shape({
    mainImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      })
    ),
  }),
};

export default ProductImages;
