/** @jsx jsx */
import { Box, Text, jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { shallowEqual, useSelector } from 'react-redux';

import Cart from '~/assets/images/icons/Cart.svg';

const CartFAB = () => {
  const lineItems = useSelector(
    (state) => state.cart.data.lineItems,
    shallowEqual
  );

  const itemCount =
    lineItems && lineItems.reduce((acc, item) => acc + item.quantity, 0);
  return (
    <Link to="/cart">
      <Box
        sx={{
          position: 'fixed',
          top: '19px',
          right: '19px',
          height: '50px',
          width: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'alizarinCrimson',
          borderRadius: '50%',
          boxShadow: '0px 20px 20px -10px rgba(0,0,0,0.4)',
          transition: 'all 0.25s',
          '&:hover': {
            transform: 'scale(1.1)',
          },
          zIndex: 5,
        }}
      >
        <Text
          sx={{
            position: 'absolute',
            left: '50%',
            top: '60%',
            transform: 'translate(-50%, -60%)',
            color: 'chatelle',
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '16.8px',
            letterSpacing: '-.3px',
          }}
        >
          {itemCount}
        </Text>
        <Cart />
      </Box>
    </Link>
  );
};

export default CartFAB;
