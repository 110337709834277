/** @jsx jsx */
import { Box, Container, Flex, jsx } from 'theme-ui';
import { Link } from 'gatsby';

import CartFAB from '~/components/Generic/Cart/FloatingActionButton';
import AugustWhite from '~/assets/images/logos/August_Main_Logo_Hero_WHITE.svg';
import ImageCarousel from '~/components/Generic/ImageCarousel';
import RelatedProducts from '~/components/Generic/RelatedProducts';
import ProductDetails from '~/components/Product/Details';
import ProductImages from '~/components/Product/Images';
import InnerCycleForm from '~/components/Splash/Sections/Merch/InnerCycleForm';

const ProductPage = ({ product, bg, bgMobile }) => {
  const { relatedProducts, images } = product;

  const collection = product.collection.find((c) => c);

  return (
    <Box
      sx={{
        backgroundImage: [
          `url(${bgMobile.fluid.srcWebp})`,
          `url(${bgMobile.fluid.srcWebp})`,
          `url(${bg.fluid.srcWebp})`,
        ],
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Link to="/shop">
        <Box
          sx={{
            justifyContent: 'center',
            '& > svg': { height: '70px' },
            display: ['none', 'none', 'none', 'none', 'flex'],
            paddingTop: '40px',
          }}
        >
          <AugustWhite />
        </Box>
      </Link>
      <Box sx={{ display: ['none', 'none', 'none', 'none', 'block'] }}>
        <CartFAB />
      </Box>
      <Container>
        <Flex
          sx={{
            flexDirection: ['column', 'column', 'column', 'row'],
            marginTop: ['86px', '86px', '105px', '105px', '18px'],
          }}
        >
          <Box
            sx={{
              display: ['block', 'block', 'block', 'none', 'none'],
              width: '92%',
              margin: 'auto',
              '& .carousel-slider': {
                overflow: 'hidden',
              },
              '& .slider-wrapper': {
                overflow: 'hidden',
              },
            }}
          >
            <ImageCarousel images={images} />
          </Box>
          <Box
            sx={{
              width: '50%',
              display: ['none', 'none', 'none', 'block', 'block'],
            }}
          >
            <ProductImages product={product} />
          </Box>
          <ProductDetails
            product={product}
            collectionName={collection.slug}
            sx={{
              width: [null, '92%', '92%', '50%', '60%'],
              marginTop: ['66px', '66px', '66px', 0],
              alignSelf: 'center',
            }}
          />
        </Flex>
      </Container>
      <Box>
        {relatedProducts && (
          <RelatedProducts
            products={relatedProducts}
            productType={collection.slug}
          />
        )}
      </Box>
      <InnerCycleForm />
    </Box>
  );
};

export default ProductPage;
